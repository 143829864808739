import styled from "styled-components"

export const MenuLayoutStyle = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	&>.ant-layout{
		flex: auto;
	}
	#demo-b{
		width: 100%;
		height: 300px;
		background-color: #f0f0f0;
	}
	#components-layout-demo-custom-trigger .trigger {
		padding: 0 24px;
		font-size: 18px;
		line-height: 64px;
		cursor: pointer;
		transition: color 0.3s;
	}
	
	#components-layout-demo-custom-trigger .trigger:hover {
		color: #1890ff;
	}
	
	#components-layout-demo-custom-trigger .logo {
		height: 32px;
		margin: 16px;
		background: rgba(255, 255, 255, 0.3);
	}
	
	.site-layout {
		.site-layout-background,
		.site-header-background{
			background: #fff;
		}
	}
	.logo{
		height: 32px;
	    margin: 16px;
	    background: rgba(255, 255, 255, 0.2);
	}
	.site-layout-background{
        margin: 24px 16px;
        min-height: 280px;
	}
`

export const BaseLayoutStyle = styled.div`
	width: 100vw;
	height: 100vh;
	display:flex;
	justify-content: center;
`
